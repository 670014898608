import {
  MAX_ATTACHMENT_SIZE,
  CHAT_STANDARDS_MAX_ATTACHMENT_SIZE,
  MAX_NO_ATTACHMENTS,
  OFFICE_EXT,
  OFFICE_ATTACHMENT_FILETYPES,
  PDF_EXT,
  ATTACHMENT_EXT,
  ATTACHMENT_FILETYPES,
} from '../config/config'
import { AppColour } from './AppColourConstants'
import { AppRoute } from './AppRouteConstants'
import { FileMimeType } from './FileTypeEnum'

export const ChatTypeEnum = {
  NONE: -1,
  RECALL: 0,
  GPT: 1,
  CODE: 2,
  WINWISE: 4,
  BAMBOO: 5,
  CUSTOM_RECALL_APP: 6,
  BHP_STANDARDS: 7,
  RIO_TINTO_STANDARDS: 8,
  AUSNET_STANDARDS: 9,
  PM_APP: 10,
}

export const ChatTypeToPath = {
  [ChatTypeEnum.RECALL]: 'chat-recall',
  [ChatTypeEnum.GPT]: 'chat-gpt',
  [ChatTypeEnum.CODE]: 'chat-code',
  [ChatTypeEnum.BAMBOO]: 'chat-bamboo',
  [ChatTypeEnum.CUSTOM_RECALL_APP]: 'chat-custom-recall-app',
  [ChatTypeEnum.BHP_STANDARDS]: 'chat-bhp-standards',
  [ChatTypeEnum.RIO_TINTO_STANDARDS]: 'chat-rio-tinto-standards',
  [ChatTypeEnum.AUSNET_STANDARDS]: 'chat-ausnet-standards',
  [ChatTypeEnum.PM_APP]: 'chat-pm',
  [ChatTypeEnum.WINWISE]: AppRoute.WINWISE_THREAD,
}

export const ChatTypeToLabel = {
  [ChatTypeEnum.RECALL]: { label: 'Recall', desktop: 'Recall', mobile: 'R', colour: AppColour.RECALL_CHAT },
  [ChatTypeEnum.GPT]: { label: 'Secure ChatGPT', desktop: 'GPT', mobile: 'G', colour: AppColour.GPT_CHAT },
  [ChatTypeEnum.CODE]: { label: 'Code Assistant', desktop: 'Code', mobile: 'C', colour: AppColour.CODE_CHAT },
  [ChatTypeEnum.WINWISE]: {
    label: 'WinWise by clientSense',
    desktop: 'WinWise by clientSense',
    mobile: 'W',
    colour: AppColour.WINWISE,
  },
  [ChatTypeEnum.BAMBOO]: { label: 'Bamboo', desktop: 'Bamboo', mobile: 'B', colour: AppColour.BAMBOO },
  [ChatTypeEnum.CUSTOM_RECALL_APP]: {
    label: 'Knowledge Agent',
    desktop: 'Knowledge Agent',
    mobile: 'C',
    colour: AppColour.CUSTOM_RECALL_APP,
  },
  [ChatTypeEnum.BHP_STANDARDS]: {
    label: 'BHP Standards',
    desktop: 'BHP Standards',
    mobile: 'B',
    colour: AppColour.BHP_STANDARDS,
  },
  [ChatTypeEnum.RIO_TINTO_STANDARDS]: {
    label: 'Rio Tinto Standards',
    desktop: 'Rio Tinto Standards',
    mobile: 'R',
    colour: AppColour.RIO_TINTO_STANDARDS,
  },
  [ChatTypeEnum.AUSNET_STANDARDS]: {
    label: 'AusNet Standards',
    desktop: 'AusNet Standards',
    mobile: 'A',
    colour: AppColour.AUS_NET_STANDARDS,
  },
  [ChatTypeEnum.PM_APP]: { label: 'PM Assistant', desktop: 'PM Assistant', mobile: 'P', colour: AppColour.PM_APP },
}

export const ChatTypeStream = {
  [ChatTypeEnum.RECALL]: true,
  [ChatTypeEnum.GPT]: true,
  [ChatTypeEnum.CODE]: false,
  [ChatTypeEnum.CUSTOM_RECALL_APP]: true,
  [ChatTypeEnum.BHP_STANDARDS]: true,
  [ChatTypeEnum.RIO_TINTO_STANDARDS]: true,
  [ChatTypeEnum.AUSNET_STANDARDS]: true,
  [ChatTypeEnum.PM_APP]: true,
  [ChatTypeEnum.WINWISE]: false,
}

export const ChatTypeActions = {
  loading: { clipboard: false, info: true, rating: false, feedback: false, edit: false },
  [ChatTypeEnum.RECALL]: { clipboard: true, info: true, rating: true, feedback: true, edit: true },
  [ChatTypeEnum.GPT]: { clipboard: true, info: true, rating: false, feedback: false, edit: false },
  [ChatTypeEnum.CODE]: { clipboard: true, info: true, rating: true, feedback: false, edit: false },
  [ChatTypeEnum.CUSTOM_RECALL_APP]: { clipboard: true, info: true, rating: false, feedback: false, edit: true },
  [ChatTypeEnum.BHP_STANDARDS]: { clipboard: true, info: true, rating: true, feedback: false, edit: true },
  [ChatTypeEnum.RIO_TINTO_STANDARDS]: { clipboard: true, info: true, rating: true, feedback: true, edit: true },
  [ChatTypeEnum.AUSNET_STANDARDS]: { clipboard: true, info: true, rating: true, feedback: true, edit: true },
  [ChatTypeEnum.PM_APP]: { clipboard: true, info: true, rating: true, feedback: true, edit: true },
  [ChatTypeEnum.WINWISE]: { clipboard: true, info: true, rating: true, feedback: true, edit: true },
}

export const ChatTypeInput = {
  [ChatTypeEnum.RECALL]: { focus: false, file: false, fileDrop: false, expand: false },
  [ChatTypeEnum.GPT]: { focus: false, file: true, fileDrop: true, expand: false },
  [ChatTypeEnum.CODE]: { focus: false, file: false, fileDrop: false, expand: false },
  [ChatTypeEnum.CUSTOM_RECALL_APP]: { focus: false, file: false, fileDrop: false, expand: false },
  [ChatTypeEnum.BHP_STANDARDS]: { focus: false, file: true, fileDrop: false, expand: false },
  [ChatTypeEnum.RIO_TINTO_STANDARDS]: { focus: false, file: true, fileDrop: false, expand: false },
  [ChatTypeEnum.AUSNET_STANDARDS]: { focus: false, file: true, fileDrop: false, expand: false },
  [ChatTypeEnum.PM_APP]: { focus: false, file: false, fileDrop: false, expand: false },
  [ChatTypeEnum.WINWISE]: { focus: false, file: false, fileDrop: false, expand: true },
}

export const ChatTypeConfig = {
  [ChatTypeEnum.RECALL]: { maxSize: 0, maxFiles: 0, extensions: [] },
  [ChatTypeEnum.GPT]: {
    maxSize: MAX_ATTACHMENT_SIZE,
    maxFiles: MAX_NO_ATTACHMENTS,
    extensions: [...ATTACHMENT_EXT, ...ATTACHMENT_FILETYPES, ...OFFICE_EXT, ...OFFICE_ATTACHMENT_FILETYPES],
  },
  [ChatTypeEnum.CODE]: { maxSize: 0, maxFiles: 0, extensions: [] },
  [ChatTypeEnum.CUSTOM_RECALL_APP]: { maxSize: 0, maxFiles: 0, extensions: [] },
  [ChatTypeEnum.BHP_STANDARDS]: {
    maxSize: MAX_ATTACHMENT_SIZE,
    maxFiles: 1,
    extensions: [...PDF_EXT, ...OFFICE_EXT, ...OFFICE_ATTACHMENT_FILETYPES, FileMimeType.PDF],
  },
  [ChatTypeEnum.RIO_TINTO_STANDARDS]: {
    maxSize: CHAT_STANDARDS_MAX_ATTACHMENT_SIZE,
    maxFiles: 1,
    extensions: [...PDF_EXT, ...OFFICE_EXT, ...OFFICE_ATTACHMENT_FILETYPES, FileMimeType.PDF],
  },
  [ChatTypeEnum.AUSNET_STANDARDS]: {
    maxSize: CHAT_STANDARDS_MAX_ATTACHMENT_SIZE,
    maxFiles: 1,
    extensions: [...PDF_EXT, ...OFFICE_EXT, ...OFFICE_ATTACHMENT_FILETYPES, FileMimeType.PDF],
  },
  [ChatTypeEnum.PM_APP]: { maxSize: 0, maxFiles: 0, extensions: [] },
  [ChatTypeEnum.WINWISE]: { maxSize: 0, maxFiles: 0, extensions: [] },
}

export const ChatTypeToPrompt = {
  [ChatTypeEnum.RECALL]: {
    start: 'ask_ques_start',
    follow: 'ask_ques_following',
    loading: 'ask_ques_loading',
  },
  [ChatTypeEnum.GPT]: {
    start: 'ask_ques_start',
    follow: 'ask_ques_following2',
    loading: 'ask_ques_loading',
  },
  [ChatTypeEnum.CODE]: {
    start: 'ask_ques_start',
    follow: 'ask_ques_following',
    loading: 'ask_ques_loading',
  },
  [ChatTypeEnum.CUSTOM_RECALL_APP]: {
    start: 'ask_ques_start',
    follow: 'ask_ques_following',
    loading: 'ask_ques_loading',
  },
  [ChatTypeEnum.BHP_STANDARDS]: {
    start: 'ask_ques_start',
    follow: 'ask_ques_following',
    loading: 'ask_ques_loading',
  },
  [ChatTypeEnum.RIO_TINTO_STANDARDS]: {
    start: 'ask_ques_start',
    follow: 'ask_ques_following',
    loading: 'ask_ques_loading',
  },
  [ChatTypeEnum.AUSNET_STANDARDS]: {
    start: 'ask_ques_start',
    follow: 'ask_ques_following',
    loading: 'ask_ques_loading',
  },
  [ChatTypeEnum.PM_APP]: {
    start: 'ask_ques_start',
    follow: 'ask_ques_following',
    loading: 'ask_ques_loading',
  },
  [ChatTypeEnum.WINWISE]: {
    start: 'ask_ques_start',
    follow: 'ask_ques_following',
    loading: 'ask_ques_loading',
  },
}
