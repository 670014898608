import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js'

let auth0ClientInstance: Auth0Client
let auth0Audience = ''
let auth0Scope = ''
let auth0Roles = ''

const initAuth0Client = async (
  clientId: string,
  domain: string,
  redirectUri: string,
  scope: string,
  audience: string,
  roles: string,
): Promise<void> => {
  const clientApplicationInstance = await createAuth0Client({
    domain: domain,
    clientId: clientId,
    authorizationParams: {
      redirect_uri: redirectUri,
      audience: audience,
      scope: scope,
    },
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
  })

  auth0ClientInstance = clientApplicationInstance
  auth0Audience = audience
  auth0Scope = scope
  auth0Roles = roles
}

const getAuth0Config = (): {
  auth0ClientInstance: Auth0Client
  auth0Audience: string
  auth0Scope: string
  auth0Roles: string
} => {
  if (!auth0ClientInstance) {
    throw new Error("Auth0 Client hasn't been initialized")
  }
  return { auth0ClientInstance, auth0Audience, auth0Scope, auth0Roles }
}

export { initAuth0Client, getAuth0Config }
