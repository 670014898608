import { FC } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@aurecon-creative-technologies/styleguide'

import { ReactComponent as Logo } from '../assets/Recall_light.svg'
import { ReactComponent as AureconLogo } from '../assets/aurecon_logo_white.svg'

import { ABOUT_URL, JIRA_SUPPORT_URL } from '../config/config'
import { appInsightsInstance } from '../api/AppInsights'
import LanguageSwitch from '../components/LanguageSwitch'
import { useLanguages } from '../hooks/useLanguages'

import Style from '../styles/Login.module.sass'
import { useAppConfig } from '../hooks/useAppConfig'
import { ClientEnum } from '../enums/ClientEnum'

interface IState {
  from: { pathname: string }
}

const Login: FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const location = useLocation()
  const { t } = useLanguages(true)
  const appConfig = useAppConfig()

  const appInsights = appInsightsInstance()
  if (appInsights) appInsights.trackPageView({ name: 'Login' })

  if (isAuthenticated) return <Navigate to='/' />

  const handleButtonClick = (url: string) => {
    window.open(url)
  }

  const handleLogin = () => {
    const state = location.state as IState

    if (state?.from?.pathname?.toLowerCase() === '/login') return

    const targetUrl = state ? `/#${state.from.pathname}` : '/'

    loginWithRedirect({
      appState: { targetUrl },
      connection_scope: `offline_access`,
    })
  }

  return (
    <div className={`${Style.loginPage} login`}>
      <div className={Style.loginContainer}>
        <div className={Style.header}>
          <AureconLogo height={30} />
        </div>
        <div className={Style.content}>
          <h2>{t('welcome')}</h2>
          <Logo className={Style.logo} />
          <p>{t('greeting')}</p>
          <Button type='primary' cssClass='is-white' label={t('log_in')} onClick={handleLogin} />
        </div>
        <div className={Style.content} />
        {appConfig?.CLIENT_NAME !== ClientEnum.TF_NSW && (
          <div className={Style.footer}>
            <Button
              type='text'
              cssClass='is-white'
              label={t('about')}
              onClick={() => handleButtonClick(`${ABOUT_URL}`)}
            />
            <Button
              type='text'
              cssClass='is-white'
              label={t('contact')}
              onClick={() => handleButtonClick(`${JIRA_SUPPORT_URL}`)}
            />

            <div className={Style.languageSwitch}>
              <LanguageSwitch login />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Login
