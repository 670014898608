import { FC, useCallback, useEffect, useState } from 'react'
import RecallModal from '../common/RecallModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import { GetDrives, GetFilesFolder, GetFilesRoot, GetSites, IMSGraphFile } from '../../api/MSGraphService'
import { LevelEnum } from '../../enums/LevelEnum'
import TableFileBrowser from './TableFileBrowser'
import { useMsalInfo } from '../../hooks/useMsalInfo'
import { ISharepointConfig, ISharepointFileModel } from '../../models/INomicModel'
import Style from '../../styles/TableFileBrowser.module.sass'
import debounce from 'debounce'
import { useAppConfig } from '../../hooks/useAppConfig'
import { SHAREPOINT_ALLOWED_FILE_TYPES, SHAREPOINT_MAX_SEARCH_RESULT } from '../../config/config'

interface ISharePointModalProps {
  open: boolean
  onClose: () => void
  onNext: (files: ISharepointFileModel[]) => void
  selectedSharepointFiles?: ISharepointFileModel[]
}

const SharePointModal: FC<ISharePointModalProps> = (props) => {
  const { open, onClose, onNext, selectedSharepointFiles } = props
  const [tableFiles, setTableFiles] = useState<IMSGraphFile[]>([])
  const [tableLocations, setTableLocations] = useState<IMSGraphFile[]>([])
  const [selectedFiles, setSelectedFiles] = useState<ISharepointFileModel[]>(selectedSharepointFiles ?? [])
  const [sites, setSites] = useState<IMSGraphFile[]>([])
  const [loading, setLoading] = useState(false)
  const [sharepointToken, setSharepointToken] = useState('')
  const [sitesLoading, setSitesLoading] = useState<boolean>(false)
  const [sharepointConfig, setSharepointConfig] = useState<ISharepointConfig>({
    maxResult: SHAREPOINT_MAX_SEARCH_RESULT,
    allowedTypes: SHAREPOINT_ALLOWED_FILE_TYPES,
  })

  const { getToken } = useMsalInfo()
  const config = useAppConfig()

  useEffect(() => {
    const getConfig = async () => {
      if (!config) {
        setLoading(true)
        return
      }

      setSharepointConfig({
        maxResult: config.SHAREPOINT_MAX_SEARCH_RESULT,
        allowedTypes: config.SHAREPOINT_ALLOWED_FILE_TYPES,
      })
      setLoading(false)
    }

    getConfig()
  }, [config])

  const handleModalClose = () => {
    setTableLocations([])
    setTableFiles([])
    onClose()
  }

  const handleSaveProperty = () => {
    onNext(selectedFiles)
    onClose()
  }

  useEffect(() => {
    if (sharepointToken) return

    const fechtSharepointToken = async () => {
      const token = await getToken()
      setSharepointToken(token)
    }

    fechtSharepointToken()
  }, [getToken, sharepointToken])

  const loadSitesForTable = useCallback(
    async (searchInput?: string) => {
      setSitesLoading(true)
      const files = await GetSites(sharepointToken, sharepointConfig.maxResult, searchInput)

      setSites(files)
      setTableLocations([])
      setTableFiles([])
      setSitesLoading(false)
    },
    [sharepointConfig.maxResult, sharepointToken],
  )

  const onSearchInput = debounce(async (searchInput: string) => {
    await loadSitesForTable(searchInput)
  }, 500)

  useEffect(() => {
    if (!sharepointToken) return
    loadSitesForTable()
  }, [loadSitesForTable, sharepointToken])

  const loadDrivesForTable = async (siteId: string) => {
    const drives = await GetDrives({ siteId, token: sharepointToken })
    setTableFiles(drives)
  }

  const loadRootForTable = async (driveId: string) => {
    const files = await GetFilesRoot({
      driveId,
      token: sharepointToken,
    })

    setTableFiles(files)
  }

  const findDriveId = (locs: IMSGraphFile[]) => {
    const drive = locs.find((loc) => loc.type === LevelEnum.DRIVE)
    return drive?.id ?? null
  }

  const findSiteId = (locs: IMSGraphFile[]) => {
    const site = locs.find((loc) => loc.type === LevelEnum.SITE)
    return site?.id ?? null
  }

  const loadFolderForTable = async (itemId: string) => {
    const driveId = findDriveId(tableLocations)
    const files = await GetFilesFolder({
      driveId: driveId ?? '',
      itemId,
      token: sharepointToken,
    })
    setTableFiles(files)
  }

  const tableRowClickActions = async (item: IMSGraphFile) => {
    let locs = [...tableLocations]
    let oldItem: IMSGraphFile | undefined = undefined,
      currentItem: IMSGraphFile | undefined = undefined

    const actions = {
      [LevelEnum.SITE]: async () => loadDrivesForTable(item.id),
      [LevelEnum.DRIVE]: async () => loadRootForTable(item.id),
      [LevelEnum.FOLDER]: async () => loadFolderForTable(item.id),
    }

    if (item.type === LevelEnum.SITE) {
      await actions[item.type]()
      return { locs: [item], oldItem, currentItem }
    }

    if ([LevelEnum.DRIVE, LevelEnum.FOLDER].includes(item.type)) {
      await actions[item.type]()
      locs = [...locs, item]
      return { locs, oldItem, currentItem }
    }

    oldItem = locs.pop()
    currentItem = locs[locs.length - 1]
    return { locs, oldItem, currentItem }
  }

  const oldItemActions = (currentItem: IMSGraphFile, oldItemType: number) => {
    const actions = {
      [LevelEnum.SITE]: async () => {
        await loadSitesForTable()
      },
      [LevelEnum.DRIVE]: async () => {
        const siteId = findSiteId(tableLocations) ?? ''
        await loadDrivesForTable(siteId)
      },
      [LevelEnum.FOLDER]: async () => {
        const driveId = findDriveId(tableLocations) ?? ''
        currentItem?.type === LevelEnum.DRIVE
          ? await loadRootForTable(driveId)
          : await loadFolderForTable(currentItem.id)
      },
    }

    return actions[oldItemType]
  }

  const onTableBreadcrumbClick = async (index: number) => {
    if (!tableLocations) return
    const newLocations = tableLocations.slice(0, index + 1)
    setTableLocations(newLocations)

    if (index === -1) {
      setTableFiles([])
      await loadSitesForTable()
      return
    }

    const currentItem = newLocations[newLocations.length - 1]

    await tableRowClickActions(currentItem)
    setLoading(false)
  }

  const onTableRowClick = async (item: IMSGraphFile) => {
    setLoading(true)
    const locObj = await tableRowClickActions(item)
    if (!locObj) return

    if (!locObj.locs.length) setTableFiles([])
    setTableLocations(locObj.locs)
    setLoading(false)

    const oldItem = locObj.oldItem
    const currentItem = locObj.currentItem

    if (!oldItem || !currentItem) return

    await oldItemActions(currentItem, oldItem.type)()
  }

  return (
    <RecallModal
      showActionButtons
      chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
      isShowing={open}
      onSave={handleSaveProperty}
      onClose={handleModalClose}
      disabled={!selectedFiles.length}
      size='large'
      labelYes='Next'
      cssClass={Style.sharePointModal}
    >
      <TableFileBrowser
        locations={tableLocations}
        files={tableFiles}
        onRowClick={onTableRowClick}
        onSelect={(files: ISharepointFileModel[]) => setSelectedFiles(files)}
        onBreadcrumbClick={onTableBreadcrumbClick}
        loading={loading}
        selectedSharepointFiles={selectedSharepointFiles}
        sites={sites}
        sitesLoading={sitesLoading}
        onSearchInput={(value) => {
          onSearchInput.clear()
          onSearchInput(value)
        }}
        sharepointToken={sharepointToken}
        sharepointConfig={sharepointConfig}
      />
    </RecallModal>
  )
}

export default SharePointModal
