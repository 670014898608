import { FC } from 'react'
import Style from '../../styles/common/FileUploadAllowTypesMessage.module.sass'
import { useLanguages } from '../../hooks/useLanguages'
import { InfoTooltip } from '@aurecon-creative-technologies/styleguide'

export interface IFileUploadAllowTypesMessageProps {
  message: string
  allowedFileTypes: string[]
}

const FileUploadAllowTypesMessage: FC<IFileUploadAllowTypesMessageProps> = ({ message, allowedFileTypes }) => {
  const { t } = useLanguages()

  return (
    <span className='tooltip'>
      <InfoTooltip
        show={
          <span className={Style.tooltip}>
            <div>{t('upload_title2_tolltip')}</div>
            <div>{allowedFileTypes.join(', ')}</div>
          </span>
        }
      />
      {message}
    </span>
  )
}

export default FileUploadAllowTypesMessage
