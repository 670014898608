import { FC } from 'react'

import { ReactComponent as RecallMenuIcon } from '../../src/assets/Recall_menu_icon.svg'
import { ReactComponent as GPTMenuIcon } from '../../src/assets/ChatGPT_menu_icon.svg'
import { ReactComponent as WinWiseLogo } from '../../src/assets/WinWise_logo.svg'
import { ReactComponent as BambooLogo } from '../../src/assets/Bamboo_logo.svg'
import { ReactComponent as CRALogo } from '../../src/assets/customRecall_icon.svg'
import { ReactComponent as BHPLogo } from '../../src/assets/BHP_logo.svg'
import { ReactComponent as PMLogo } from '../../src/assets/PM_logo.svg'
import { appInsightsInstance } from '../api/AppInsights'
import Page from '../components/Page'
import { AppColour } from '../enums/AppColourConstants'
import { useShowFeature } from '../hooks/useShowFeature'
import { FeatureFlagEnum } from '../enums/FeatureFlagEnum'
import { useChangeChat } from '../hooks/useChangeChat'
import { useLanguages } from '../hooks/useLanguages'

import Style from '../styles/Apps.module.sass'
import AppList from '../components/AppList'
import { useAppConfig } from '../hooks/useAppConfig'
import { ClientEnum } from '../enums/ClientEnum'
import classNames from 'classnames'

const textColourBlack = '#000000'
const textColourWhite = '#ffffff'

const Home: FC = () => {
  const {
    openRecallChat,
    openChatGPT,
    openWinWise,
    openBamboo,
    openCustomRecallApp,
    openBHPStandards,
    openRioTintoStandards,
    openAusNetStandards,
    openPMAssistant,
  } = useChangeChat()
  const { t } = useLanguages()

  const appInsights = appInsightsInstance()
  if (appInsights) appInsights.trackPageView({ name: 'Apps' })
  const showBambooChat = useShowFeature(FeatureFlagEnum.ShowBambooChat)
  const showCustomRecallChat = useShowFeature(FeatureFlagEnum.ShowCustomRecallApp)
  const showBHPStandardsApp = useShowFeature(FeatureFlagEnum.ShowBHPStandardsApp)
  const showRioTintoStandardsApp = useShowFeature(FeatureFlagEnum.ShowRioTintoStandardsApp)
  const showAusNetStandardsApp = useShowFeature(FeatureFlagEnum.ShowAusNetStandardsApp)
  const showPMApp = useShowFeature(FeatureFlagEnum.ShowPMApp)
  const showWinWiseApp = useShowFeature(FeatureFlagEnum.ShowWinWiseApp)
  const showRecallChat = useShowFeature(FeatureFlagEnum.ShowRecallChat)
  const showSecureChatGpt = useShowFeature(FeatureFlagEnum.ShowSecureChatGpt)

  const appConfig = useAppConfig()

  const isAurecon = appConfig?.CLIENT_NAME === ClientEnum.AURECON

  const apps = [
    {
      name: 'Recall Chat',
      sortOrder: isAurecon ? 1 : 2,
      icon: RecallMenuIcon,
      content: (
        <>
          <p>{t('recall_content')}</p>
          <p>{t('recall_content2')}</p>
        </>
      ),
      buttonLabel: t('recall_content3'),
      onClick: openRecallChat,
      buttonColor: AppColour.RECALL_CHAT,
      textColor: textColourBlack,
      enabled: showRecallChat.enabled,
    },
    {
      name: 'Secure ChatGPT',
      sortOrder: isAurecon ? 2 : 1,
      icon: GPTMenuIcon,
      content: (
        <>
          <p>{t('securegpt_content')}</p>
          <p>{t('securegpt_content2')}</p>
        </>
      ),
      buttonLabel: t('securegpt_content3'),
      onClick: openChatGPT,
      buttonColor: AppColour.GPT_CHAT,
      textColor: textColourBlack,
      enabled: showSecureChatGpt.enabled,
    },
    {
      name: '',
      sortOrder: 3,
      icon: WinWiseLogo,
      content: (
        <>
          <p>{t('winwise_content')}</p>
          <p>{t('winwise_content2')}</p>
        </>
      ),
      buttonLabel: t('winwise_content3'),
      onClick: openWinWise,
      buttonColor: AppColour.WINWISE_APP_PAGE,
      textColor: textColourBlack,
      enabled: showWinWiseApp.enabled,
    },
    {
      name: 'Bamboo',
      sortOrder: 4,
      icon: BambooLogo,
      content: (
        <>
          <p>{t('bamboo_content')}</p>
          <p>{t('bamboo_content2')}</p>
        </>
      ),
      buttonLabel: t('bamboo_content3'),
      buttonColor: AppColour.BAMBOO_APP_PAGE,
      onClick: openBamboo,
      textColor: textColourBlack,
      experimental: true,
      enabled: showBambooChat.enabled,
    },
    {
      name: 'Knowledge Agent',
      sortOrder: 5,
      icon: CRALogo,
      content: (
        <>
          <p>{t('custom_app_content')}</p>
          <p>{t('custom_app_content2')}</p>
        </>
      ),
      buttonLabel: 'Knowledge Agent',
      onClick: openCustomRecallApp,
      buttonColor: AppColour.CUSTOM_RECALL_APP,
      textColor: textColourWhite,
      enabled: showCustomRecallChat.enabled,
    },
    {
      name: 'Standards',
      sortOrder: 6,
      icon: BHPLogo,
      content: (
        <>
          <p>{t('bhp_content')}</p>
          <p>{t('standards_content2')}</p>
        </>
      ),
      buttonLabel: t('bhp_content3'),
      onClick: openBHPStandards,
      buttonColor: AppColour.BHP_STANDARDS,
      textColor: textColourWhite,
      enabled: showBHPStandardsApp.enabled,
    },
    {
      name: 'Rio Tinto Standards',
      sortOrder: 7,
      icon: null,
      content: (
        <>
          <p>{t('rio_content')}</p>
          <p>{t('standards_content2')}</p>
        </>
      ),
      buttonLabel: t('rio_content2'),
      onClick: openRioTintoStandards,
      buttonColor: AppColour.RIO_TINTO_STANDARDS,
      textColor: textColourWhite,
      enabled: showRioTintoStandardsApp.enabled,
    },
    {
      name: 'AusNet Standards',
      sortOrder: 8,
      icon: null,
      content: (
        <>
          <p>{t('ausnet_content')}</p>
          <p>{t('standards_content2')}</p>
        </>
      ),
      buttonLabel: t('ausnet_content2'),
      onClick: openAusNetStandards,
      buttonColor: AppColour.AUS_NET_STANDARDS,
      textColor: textColourWhite,
      enabled: showAusNetStandardsApp.enabled,
    },
    {
      name: 'PM Assistant',
      sortOrder: 9,
      icon: PMLogo,
      content: (
        <p>
          PM Assistant allows users to query Aurecon’s project management learnings, standards and processes. With our
          tailored knowledge base, users can access expert guidance on commercial, governance, and delivery frameworks
          to accelerate decision-making.
        </p>
      ),
      buttonLabel: 'Ask PM Assistant',
      buttonColor: AppColour.PM_APP,
      onClick: openPMAssistant,
      textColor: textColourBlack,
      enabled: showPMApp.enabled,
    },
  ]

  const enabledApps = apps.filter((app) => app.enabled)

  return (
    <Page menu contentWrapper contentWrapperWide>
      <h1>{t('app_header')}</h1>

      <div
        className={classNames(Style.cards, {
          [Style.onlyOneItem]: enabledApps.length === 1,
          [Style.haveTwoItems]: enabledApps.length === 2,
        })}
      >
        <AppList apps={apps} />
      </div>
    </Page>
  )
}

export default Home
