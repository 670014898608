import { CSSProperties, FC, useEffect, useMemo, useState } from 'react'
import { Button, Grid, Icon, Loader } from '@aurecon-creative-technologies/styleguide'
import { AppSlidePositionEnum } from '../enums/AppSlidePosition'

import { IAppSlide } from '../models/api/IHomepageModels'
import classNames from 'classnames'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { AppSlides, FullScreen, Language } from '../stores/AppStore'

import Style from '../styles/AppSlideEditor.module.sass'
import { useSwipeable } from 'react-swipeable'
import { useStopwatch } from 'react-timer-hook'
import { LanguageEnum } from '../enums/LanguageEnum'
import { UI_MOBILE_WIDTH } from '../config/config'
import { useMediaQuery } from 'react-responsive'
import { getTextFromHTML } from '../helpers/utils'

interface IAppSlideEditorProps {
  position: AppSlidePositionEnum
}

const TIME_PER_SLIDE = 10
const HOVER_WAIT_TIME = 30

const AppSlideViewer: FC<IAppSlideEditorProps> = (props) => {
  const { position } = props
  const AppSlidesLoader = useRecoilValueLoadable(AppSlides)
  const appLanguage = useRecoilValue(Language)

  const [allSlidesForPosition, setAllSlidesForPosition] = useState<IAppSlide[] | null>(null)
  const [sortOrder, setSortOrder] = useState<number>(0)
  const [mainSlides, setMainSlides] = useState<IAppSlide[]>([])
  const [timer, setTimer] = useState(TIME_PER_SLIDE)
  const isDesktop = useMediaQuery({ minWidth: UI_MOBILE_WIDTH })

  const { seconds, reset, pause } = useStopwatch({ autoStart: position === AppSlidePositionEnum.MAIN })
  const fullscreen = useRecoilValue(FullScreen)

  useEffect(() => {
    if (seconds !== timer) return
    setSortOrder((s) => (s === mainSlides.length - 1 ? 0 : s + 1))
    reset(undefined, true)
    if (timer === HOVER_WAIT_TIME) setTimer(TIME_PER_SLIDE)
  }, [mainSlides.length, position, reset, seconds, timer])

  useMemo(() => {
    if (AppSlidesLoader.state !== 'hasValue' || !AppSlidesLoader.contents) return
    const allSlides = AppSlidesLoader.contents
    const engSlides = allSlides.filter(
      (slide) =>
        slide.position === position &&
        slide.language === LanguageEnum.ENGLISH &&
        (slide.title || slide.subTitle || getTextFromHTML(slide.description)),
    )

    const slides = engSlides.map((es) => {
      if (appLanguage === LanguageEnum.ENGLISH) return es
      const langSlide = allSlides.find(
        (c) =>
          c.language === appLanguage &&
          c.position === es.position &&
          c.sortOrder === es.sortOrder &&
          (c.title || c.fileSaS || c.subTitle || getTextFromHTML(c.description)),
      )

      return langSlide || es
    })

    const sortedSlides = slides.sort((a, b) => a.sortOrder - b.sortOrder)

    if (sortedSlides.length) {
      setSortOrder(0)
    }

    if (position === AppSlidePositionEnum.MAIN && slides.length) {
      const sortedSlides = slides.sort((a, b) => a.sortOrder - b.sortOrder)
      setMainSlides(sortedSlides)
    }

    setAllSlidesForPosition(sortedSlides)
  }, [AppSlidesLoader.contents, AppSlidesLoader.state, appLanguage, position])

  const slideClasses = classNames({
    [Style.appSlide]: true,
    [Style.main]: position === AppSlidePositionEnum.MAIN,
    [Style.left]: position === AppSlidePositionEnum.LEFT,
    [Style.right]: position === AppSlidePositionEnum.RIGHT,
  })
  const slideHolderClasses = classNames({
    [Style.appSlideHolder]: true,
    [Style.viewer]: true,
    [Style.mobileView]: !isDesktop,
  })

  const onLearnMore = () => {
    if (currentSlide?.url) window.open(currentSlide?.url, '_blank')
    stopAndReset()
  }

  const handleMiniCardClick = () => {
    if (position !== AppSlidePositionEnum.MAIN && currentSlide?.url) {
      window.open(currentSlide.url, '_blank')
    }
  }

  const onDotClick = (sortOrder: number) => {
    setSortOrder(sortOrder)
    stopAndReset()
  }

  const onNextClick = () => {
    setSortOrder((prev) => (prev === mainSlides.length - 1 ? 0 : prev + 1))
    stopAndReset()
  }

  const onPrevClick = () => {
    setSortOrder((prev) => (prev === 0 ? mainSlides.length - 1 : prev - 1))
    stopAndReset()
  }

  const currentSlide = useMemo(() => {
    const slide = allSlidesForPosition?.find((s) => s.sortOrder === sortOrder && s.position === position)
    return slide
  }, [allSlidesForPosition, position, sortOrder])

  const pushStyle = useMemo(() => {
    return {
      backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0) 50%, var(--home-page-slide-bg) 100%), url(${currentSlide?.fileSaS})`,
    } as CSSProperties
  }, [currentSlide?.fileSaS])

  const disableCarouselActions = currentSlide?.position !== AppSlidePositionEnum.MAIN

  const onRightSwipe = () => {
    if (disableCarouselActions) return
    const newPos = !currentSlide?.sortOrder ? mainSlides.length - 1 : currentSlide?.sortOrder - 1
    onDotClick(newPos)
  }

  const onLeftSwipe = () => {
    if (disableCarouselActions) return
    const newPos = currentSlide?.sortOrder === mainSlides.length - 1 ? 0 : (currentSlide?.sortOrder ?? 0) + 1
    onDotClick(newPos)
  }

  const handlers = useSwipeable({
    onSwipedLeft: onLeftSwipe,
    onSwipedRight: onRightSwipe,
    trackMouse: true,
    trackTouch: true,
  })

  const stopAndReset = () => {
    if (disableCarouselActions) return
    pause()
    reset(undefined, true)
    setTimer(HOVER_WAIT_TIME)
  }

  if (!allSlidesForPosition)
    return (
      <div className={slideHolderClasses}>
        <Loader label='loading' />
      </div>
    )

  if (!allSlidesForPosition.length) return null

  const imageClasses = classNames({
    [Style.image]: true,
    [Style.imageFullWidth]: fullscreen,
  })

  return (
    <div
      {...handlers}
      onClick={position !== AppSlidePositionEnum.MAIN ? handleMiniCardClick : stopAndReset}
      style={{ cursor: position !== AppSlidePositionEnum.MAIN && currentSlide?.url ? 'pointer' : 'default' }}
      className={slideHolderClasses}
      role='none'
    >
      <div className={slideClasses}>
        <div className={Style.push} style={pushStyle} />
        <div className={Style.details}>
          <Grid row gap={12} cssClass={Style.form}>
            <Grid item xs={12} cssClass={Style.subTitle}>
              {currentSlide?.subTitle}
            </Grid>
            <Grid item xs={12} cssClass={Style.title}>
              {currentSlide?.title}
            </Grid>
            <Grid item xs={12} cssClass={Style.description}>
              <div
                className={Style.descriptionPostsWrapper}
                dangerouslySetInnerHTML={{ __html: currentSlide?.description || '' }}
              />
            </Grid>
            {position === AppSlidePositionEnum.MAIN && currentSlide?.learnMore && (
              <Grid
                item
                xs={12}
                style={{
                  padding: '4px 0px',
                }}
              >
                <Button
                  label='Learn More'
                  size='medium'
                  type='primary'
                  disabled={!currentSlide.url}
                  onClick={onLearnMore}
                  style={{
                    border: 'none',
                    padding: '8px 16px',
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
        {position !== AppSlidePositionEnum.MAIN && currentSlide?.fileSaS && (
          <div className={imageClasses}>
            <img src={currentSlide?.fileSaS} alt='slide' role='none' />
          </div>
        )}
        {position === AppSlidePositionEnum.MAIN && mainSlides.length > 1 && (
          <div className={Style.dots}>
            {mainSlides.map((slide) => (
              <Icon
                key={slide.sortOrder}
                type='circle'
                size='14px'
                onClick={() => onDotClick(slide.sortOrder)}
                cssClass={currentSlide?.sortOrder === slide.sortOrder ? Style.selected : Style.unselected}
              />
            ))}
          </div>
        )}
        {position === AppSlidePositionEnum.MAIN && mainSlides.length > 1 && (
          <>
            <div className={Style.slidesArrowLeft} onClick={onPrevClick} role='none'>
              <Icon type='chevron_left' size='28px' />
            </div>
            <div className={Style.slidesArrowRight} onClick={onNextClick} role='none'>
              <Icon type='chevron_right' size='28px' />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default AppSlideViewer
