import { DEFAULT_CHUNK_SIZE } from '../config/config'
import { ISharepointFileModel } from '../models/INomicModel'
import { IChunk } from '../models/shared/IFileModels'

export const fileSize = (fileSize: number) => {
  let size = fileSize

  if (size < 1024) return `${size}B`

  if (size < 1024 * 1024) {
    size = Math.round((size / 1024) * 100) / 100
    return `${size}KB`
  }

  if (size < 1024 * 1024 * 1024) {
    size = Math.round((size / 1024 / 1024) * 100) / 100
    return `${size}MB`
  }

  if (size < 1024 * 1024 * 1024 * 1024) {
    size = Math.round((size / 1024 / 1024 / 1024) * 100) / 100
    return `${size}GB`
  }

  return `${size}`
}

export const fileExtension = (value: string): string | undefined => value.split('.').pop()?.toLowerCase()
export const getFileChunk = (file: File, start: number, end: number) => file.slice(start, end)
export const getChunkSize = (chunkSize: number | undefined) => (chunkSize ?? DEFAULT_CHUNK_SIZE) * 1024 * 1024

export const createChunks = (file: File, chunkSize: number): IChunk[] => {
  const chunks: IChunk[] = []
  let index = 0
  let offset = 0
  while (offset < file.size) {
    const start = offset
    const end = Math.min(offset + chunkSize, file.size)
    const chunk: IChunk = {
      index,
      start,
      end,
    }

    chunks.push(chunk)
    index++
    offset = end
  }

  return chunks
}

export const generateUniqueFileName = (fileName: string, existingFileNames: string[]): string => {
  let newFileName = fileName
  let suffixNumber = 1

  while (existingFileNames.some((s) => s === newFileName)) {
    const extensionIndex = fileName.lastIndexOf('.')
    const baseName = extensionIndex === -1 ? fileName : fileName.slice(0, extensionIndex)
    const extension = extensionIndex === -1 ? '' : fileName.slice(extensionIndex)
    newFileName = `${baseName}(${suffixNumber++})${extension}`
  }

  return newFileName
}

export const getTotalFileSizeInGB = (slectedFile: ISharepointFileModel[]) => {
  const totalBytes = slectedFile.reduce((acc, file) => acc + file.size, 0)
  const gbSize = (totalBytes / (1024 * 1024 * 1024)).toFixed(2)
  return `${gbSize} GB`
}
