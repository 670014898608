export const FeatureFlagEnum = {
  ShowBambooChat: 'WEBAPP__APP__ShowBambooChat',
  ShowInfoMessage: 'WEBAPP__Frontend__ShowInfoMessage',
  UsePatronousAI: 'WEBAPP__APP__UsePatronousAIForChat',
  ShowCustomRecallApp: 'WEBAPP__APP__ShowCustomRecallApp',
  ShowBHPStandardsApp: 'WEBAPP__APP__ShowBHPStandardsApp',
  ShowRioTintoStandardsApp: 'WEBAPP__APP__ShowRioTintoStandardsApp',
  ShowAusNetStandardsApp: 'WEBAPP__APP__ShowAusNetStandardsApp',
  ShowHomepageSlides: 'WEBAPP__Frontend__ShowHomepageSlides',
  ShowSharePointFilePicker: 'WEBAPP__ShowSharePointFilePicker',
  ShowPMApp: 'WEBAPP__APP__ShowPMApp',
  ShowWinWiseApp: 'WEBAPP__APP__ShowWinWiseApp',
  ShowCodeAssistantChat: 'WEBAPP__APP__ShowCodeAssistantChat',
  ShowRecallChat: 'WEBAPP__APP__ShowRecallChat',
  ShowSecureChatGpt: 'WEBAPP__APP__ShowSecureChatGpt',
  ShowLocalisation: 'WEBAPP__APP__ShowLocalisation',
}
