import { useState, FC, useMemo, useEffect } from 'react'
import { GetFilesFolder, IMSGraphFile } from '../../api/MSGraphService'
import { LevelEnum } from '../../enums/LevelEnum'
import {
  Checkbox,
  FormInput,
  Grid,
  Icon,
  IHeader,
  Loader,
  Table,
  TableCell,
  TableRow,
  useToast,
} from '@aurecon-creative-technologies/styleguide'
import Style from '../../styles/TableFileBrowser.module.sass'
import nextId from 'react-id-generator'
import { ISharepointConfig, ISharepointFileModel } from '../../models/INomicModel'
import { useLanguages } from '../../hooks/useLanguages'
import { sortArrBy } from '../../helpers/utils'
import classNames from 'classnames'
import { SortEnum, SortType } from '../../enums/SortType'

interface ITableFileBrowserProps {
  locations: IMSGraphFile[]
  files: IMSGraphFile[]
  onRowClick: (item: IMSGraphFile) => Promise<void>
  onBreadcrumbClick: (index: number) => Promise<void>
  onSelect: (files: ISharepointFileModel[]) => void
  loading: boolean
  selectedSharepointFiles?: ISharepointFileModel[]
  sites: IMSGraphFile[]
  sitesLoading: boolean
  onSearchInput: (string: string) => void
  sharepointToken: string
  sharepointConfig: ISharepointConfig
}

const defaultSortOrder = {
  name: SortEnum.None,
  updatedDate: SortEnum.None,
  updatedBy: SortEnum.None,
}

interface ISharepointSortOrder {
  name: SortType
  updatedDate: SortType
  updatedBy: SortType
}

const TableFileBrowser: FC<ITableFileBrowserProps> = (props) => {
  const {
    files,
    locations,
    onRowClick,
    onBreadcrumbClick,
    loading,
    onSelect,
    onSearchInput,
    selectedSharepointFiles,
    sites,
    sitesLoading,
    sharepointToken,
    sharepointConfig,
  } = props
  const [selectedFiles, setSelectedFiles] = useState<ISharepointFileModel[]>(selectedSharepointFiles ?? [])
  const [sortOrder, setSortOrder] = useState<ISharepointSortOrder>(defaultSortOrder)
  const [searchInput, setSearchInput] = useState<string>('')
  const [selectedSite, setSelectedSite] = useState<IMSGraphFile>()
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [folderLoading, setFolderLoading] = useState<IMSGraphFile[]>([])
  const { t } = useLanguages()
  const MIN_SEARCH_INPUT_COUNT = 3
  const { addToast } = useToast()

  useEffect(() => {
    if (!sitesLoading) setIsSearching(false)
  }, [sitesLoading])

  const getSubfolderFiles = async (selectedItem: IMSGraphFile) => {
    const folderItems = await GetFilesFolder({
      driveId: selectedItem.driveId ?? '',
      itemId: selectedItem.id,
      token: sharepointToken,
    })

    const filteredItems = folderItems.filter((item) => {
      if (item.type !== LevelEnum.FILE) return false

      const fileName = item.name ?? ''
      const extn = fileName.split('.').length > 1 ? fileName.split('.').pop() : undefined
      if (!extn || !sharepointConfig.allowedTypes.includes(`.${extn}`)) return false

      return true
    })

    return filteredItems
  }

  const handleSelectFolder = async (selectedItem: IMSGraphFile) => {
    const foldersLoading = [...folderLoading, selectedItem]
    setFolderLoading(foldersLoading)
    const filteredItems = await getSubfolderFiles(selectedItem)
    setFolderLoading(foldersLoading.filter((item) => item.id !== selectedItem.id))

    if (!filteredItems.length) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: 'Folder does not contain any supported file types.',
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })

      return
    }

    const isRemove = selectedFiles.some((sf) => filteredItems.some((fi) => fi.id === sf.id))

    if (isRemove) {
      const newSelectedFiles = selectedFiles.filter((item) => item.parentFolderId !== selectedItem.id)
      updateSelectedFiles(newSelectedFiles)
      return
    }

    const newFiles = filteredItems.map((item) => mapSelectedFile(item))
    updateSelectedFiles([...selectedFiles, ...newFiles])
  }

  const handleCheckboxClick = async (selectedFile: IMSGraphFile) => {
    if (selectedFile.type === LevelEnum.FOLDER) {
      await handleSelectFolder(selectedFile)
      return
    }

    const isSelected = selectedFiles.some((file) => file.id === selectedFile.id)

    const newFile = mapSelectedFile(selectedFile)

    const newSelectedFiles = isSelected
      ? selectedFiles.filter((file) => file.id !== selectedFile.id)
      : [...selectedFiles, newFile]

    updateSelectedFiles(newSelectedFiles)
  }

  const handleFileClick = (item: IMSGraphFile) => {
    if (item.type === LevelEnum.FILE) return
    updateSelectedFiles([])
    onRowClick(item)
  }

  const onSort = (field: string, sort: string) => {
    const newOrder = {
      ...defaultSortOrder,
      [field]: sort,
    }

    setSortOrder(newOrder)
  }

  const handleSelectAll = async (checked: boolean) => {
    updateSelectedFiles([])
    if (!checked) return

    const folders = files.filter((item) => item.type === LevelEnum.FOLDER)
    const folderFiles = files.filter((item) => item.type === LevelEnum.FILE)
    setFolderLoading(folders)
    const promises = folders.map((item) => getSubfolderFiles(item))
    const results = await Promise.all(promises)
    const hasInvalidFolder = results.some((result) => !result.length)
    if (hasInvalidFolder) {
      addToast({
        type: 'error',
        title: t('popup_toast2'),
        message: 'Some folders does not contain any supported file types.',
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
    }

    const selectedFoldersFiles = results.filter((arr) => arr.length).flatMap((arr) => arr)
    const subFolderFiles = selectedFoldersFiles.map((item) => mapSelectedFile(item))
    const currentFolderFiles = folderFiles.map((item) => mapSelectedFile(item))

    updateSelectedFiles([...subFolderFiles, ...currentFolderFiles])
    setFolderLoading([])
  }

  const sortedFiles = useMemo(() => {
    const foundSortKey = (Object.keys(sortOrder) as [keyof ISharepointSortOrder]).find(
      (key) => sortOrder[key] === SortEnum.Asc || sortOrder[key] === SortEnum.Desc,
    )

    const filteredFiles = files.filter((file) => {
      if (file.type !== LevelEnum.FILE) return true
      // hide empty folders
      if (file.type === LevelEnum.FOLDER && file.folder?.childCount === 0) return false

      const fileName = file.name ?? ''
      const extn = fileName.split('.').length > 1 ? fileName.split('.').pop() : undefined
      if (!extn || !sharepointConfig.allowedTypes.includes(`.${extn}`)) return false

      return true
    })

    if (!foundSortKey) return filteredFiles

    const sortKey = foundSortKey
    const order = sortOrder[sortKey]

    const filesArr = sortArrBy(order as string, filteredFiles, sortKey) as IMSGraphFile[]

    return filesArr
  }, [files, sharepointConfig.allowedTypes, sortOrder])

  const tableHeaders = (): IHeader[] => {
    const headers = [
      { label: 'Name', sort: sortOrder.name, onSort: (sort: string) => onSort('name', sort) },
      { label: 'Modified By', sort: sortOrder.updatedBy, onSort: (sort: string) => onSort('updatedBy', sort) },
      { label: 'Modified', sort: sortOrder.updatedDate, onSort: (sort: string) => onSort('updatedDate', sort) },
    ] as IHeader[]

    if (sortedFiles.some((file) => [LevelEnum.FOLDER, LevelEnum.FILE].includes(file.type)) && !loading) {
      headers.unshift({
        label: '',
        onCheckbox: handleSelectAll,
        checked: sortedFiles.every((item) => {
          if (item.type === LevelEnum.FILE) return selectedFiles.some((m) => m.id === item.id)
          return selectedFiles.some((m) => m.parentFolderId === item.id)
        }),
      })
    }

    return headers
  }

  const onSearchChange = (val: string) => {
    setSearchInput(val)
    if (val.length >= MIN_SEARCH_INPUT_COUNT || val.length === 0) {
      setIsSearching(true)
      onSearchInput(val)
    }
  }

  const handleSiteClick = (site: IMSGraphFile) => {
    handleFileClick(site)
    setSelectedSite(site)
  }

  const formatDate = (dateString: string) => {
    if (!dateString) {
      return 'N/A'
    }

    const date = new Date(dateString)
    if (isNaN(date.getTime())) {
      return 'N/A'
    }

    return new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }).format(date)
  }

  const handleBreadcrumbClick = (index: number) => {
    if (index === -1) setSearchInput('')
    updateSelectedFiles([])
    onBreadcrumbClick(index)
  }

  const mapSelectedFile = (item: IMSGraphFile) => {
    return {
      id: item.id,
      name: item.name ?? '',
      webUrl: item.webUrl ?? '',
      driveId: item.driveId ?? '',
      parentFolderId: item.parentReference?.id ?? '',
      size: item.size ?? 0,
    }
  }

  const isCheckBoxSelected = (item: IMSGraphFile) => {
    if (item.type === LevelEnum.FILE) return selectedFiles.some((m) => m.id === item.id)

    return selectedFiles.some((m) => m.parentFolderId === item.id)
  }

  const updateSelectedFiles = (items: ISharepointFileModel[]) => {
    setSelectedFiles(items)
    onSelect(items)
  }

  const renderSitePanel = () => {
    if (sitesLoading) {
      return (
        <div className={Style.sitesPanel}>
          {sitesLoading && (
            <div className={Style.sitesLoader}>
              <Loader label={t('loading')} />
            </div>
          )}
        </div>
      )
    }

    return (
      <div className={Style.sitesPanel}>
        {!isSearching && !!sites.length && searchInput.length >= MIN_SEARCH_INPUT_COUNT && (
          <div className={Style.resultCount}>{sites.length} results found</div>
        )}
        {!isSearching && !sites.length ? (
          <div className={Style.resultCount}>{t('no_result')}</div>
        ) : (
          sites.map((site) => {
            return (
              <div
                key={site.id}
                className={classNames({
                  [Style.siteItem]: true,
                  [Style.selectedSite]: site.id === selectedSite?.id,
                })}
                onClick={() => handleSiteClick(site)}
                role='none'
              >
                <Icon type='folder' outlined className={Style.siteIcon} size='18px' />
                <span className={Style.siteLabel}>{site.displayName}</span>
              </div>
            )
          })
        )}
      </div>
    )
  }

  const renderTable = () => {
    return (
      <Grid row gap={2}>
        <Grid item xl={3} cssClass={Style.contentContainer}>
          {renderSitePanel()}
        </Grid>
        <Grid item xl={9} cssClass={Style.contentContainer}>
          <div className={Style.infoContainer}>
            <Icon type='lightbulb' outlined className={Style.infoIcon} />
            <div className={Style.infoTextContainer}>
              <span>Choosing a folder will not include any subfolders that may be within it</span>
              <span>{`Supported file types: (${sharepointConfig.allowedTypes.join(', ')})`}</span>
            </div>
          </div>

          <div className={Style.tableContainer}>
            <Table headers={tableHeaders()}>
              {loading ? (
                <div className={Style.loaderContainer}>
                  <Loader label={t('loading')} />
                </div>
              ) : (
                sortedFiles.map((item) => {
                  const itemName = item.name ?? ''
                  const extn = itemName.split('.').length > 1 ? itemName.split('.').pop() : undefined
                  const hasCheckbox = [LevelEnum.FILE, LevelEnum.FOLDER].includes(item.type)

                  return (
                    <TableRow rowClass={Style.tableRow} key={item.id}>
                      {hasCheckbox && !loading && (
                        <TableCell checkbox cellClass={Style.checkBoxCell}>
                          {folderLoading.some((folder) => folder.id === item.id) && item.type === LevelEnum.FOLDER ? (
                            <Loader size='extra small' />
                          ) : (
                            <Checkbox checked={isCheckBoxSelected(item)} onChange={() => handleCheckboxClick(item)} />
                          )}
                        </TableCell>
                      )}
                      <TableCell onClick={() => handleFileClick(item)}>
                        <div className={Style.iconCell}>
                          <span className={Style.fileIcon}>
                            <Icon type={extn ? 'description' : 'folder'} outlined size='18px' />
                          </span>
                          {item.name}
                        </div>
                      </TableCell>
                      <TableCell onClick={() => handleFileClick(item)}>{item.updatedBy ?? 'N/A'}</TableCell>
                      <TableCell onClick={() => handleFileClick(item)}>{formatDate(item.updatedDate ?? '')}</TableCell>
                    </TableRow>
                  )
                })
              )}
            </Table>
          </div>
        </Grid>
      </Grid>
    )
  }

  const renderSearchbar = () => {
    return (
      <FormInput
        type='text'
        icon='search'
        cssClass={Style.searchBox}
        placeholder='Search SharePoint Site...'
        value={searchInput}
        onChange={onSearchChange}
      />
    )
  }

  const renderBreadcrumbs = () => {
    const breadcrumbs = [
      { name: 'Home', index: -1 },
      ...locations.map((loc, index) => ({
        name: loc.displayName ?? loc.name,
        index,
      })),
    ]

    return (
      <div className={Style.breadcrumbs}>
        {breadcrumbs.map((crumb, idx) => (
          <span key={nextId()} className={Style.breadcrumbContainer}>
            <span onClick={() => handleBreadcrumbClick(crumb.index)} className={Style.breadcrumbItem} role='none'>
              {crumb.name ?? ''}
            </span>
            {idx < breadcrumbs.length - 1 && <Icon type='arrow_forward_ios' size='16px' />}
          </span>
        ))}
      </div>
    )
  }

  return (
    <div className={Style.fileBrowserLayout}>
      <div className={Style.mainContent}>
        <div className={Style.topContainer}>
          <Grid row gap={12}>
            <Grid item xs={3}>
              {renderSearchbar()}
            </Grid>
            <Grid item xs={9}>
              {renderBreadcrumbs()}
            </Grid>
          </Grid>
        </div>
        {renderTable()}
      </div>
    </div>
  )
}

export default TableFileBrowser
