import { FC, SVGProps } from 'react'

import AppCard from '../components/AppCard'

interface IAppList {
  apps: {
    name: string
    icon: FC<SVGProps<SVGSVGElement>> | null
    content: JSX.Element
    buttonLabel: string
    buttonColor: string
    textColor: string
    enabled: boolean
    experimental?: boolean
    onClick: () => void
    sortOrder: number
  }[]
}

const AppList: FC<IAppList> = (props) => {
  const { apps } = props

  const sortedApps = [...apps].sort((a, b) => a.sortOrder - b.sortOrder)

  return (
    <>
      {sortedApps.map((app) => (
        <>
          {app.enabled && (
            <AppCard
              key={app.name}
              appName={app.name}
              AppIcon={app.icon}
              appContents={app.content}
              appButtonLabel={app.buttonLabel}
              buttonColour={app.buttonColor}
              onClick={app.onClick}
              textColor={app.textColor}
              experimental={app.experimental}
            />
          )}
        </>
      ))}
    </>
  )
}

export default AppList
