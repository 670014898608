import ReactDOM from 'react-dom/client'
import App from './App'
import { Auth0Provider } from '@auth0/auth0-react'
import { AppState } from '@auth0/auth0-react/dist/auth0-provider'

import './styles/index.sass'

require('./helpers/react-fixes')

import './helpers/i18n'
import { MsalProvider } from '@azure/msal-react'
import { getMsalClientInstance } from './auth/msalClientInstance'
import { useEffect, useState } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import { getAppConfig, IAuth0Config } from './api/AppService'
import LoadingScreen from './components/LoadingScreen'
import { initAuth0Client } from './auth/auht0ClientInstance'
import { getClientTheme, updateBrowserTabInfo } from './helpers/utils'

const defaultConfig: IAuth0Config = {
  AUTH0_DOMAIN: '',
  AUTH0_CLIENT_ID: '',
  AUTH0_AUDIENCE: '',
  AUTH0_ROLES: '',
  AUTH0_SCOPE: ``,
}

const onRedirectCallback = (appState?: AppState) => {
  window.location.href = appState?.targetUrl ? appState.targetUrl : '/'
}

const Main = () => {
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication | null>(null)
  const [config, setConfig] = useState<IAuth0Config>(defaultConfig)
  const [loadingConfig, setLoadingConfig] = useState(false)

  const getMainElement = (element: JSX.Element): JSX.Element => {
    return (
      <Auth0Provider
        domain={config.AUTH0_DOMAIN}
        clientId={config.AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens={true}
        cacheLocation='localstorage'
        scope={config.AUTH0_SCOPE}
        audience={config.AUTH0_AUDIENCE}
      >
        {element}
      </Auth0Provider>
    )
  }

  useEffect(() => {
    const loadConfig = async () => {
      setLoadingConfig(true)

      const configResponse = await getAppConfig({})

      const config = configResponse?.data ?? null
      const auth0Config = { ...(configResponse?.data ?? defaultConfig) }
      await initAuth0Client(
        auth0Config.AUTH0_CLIENT_ID,
        auth0Config.AUTH0_DOMAIN,
        window.location.origin,
        auth0Config.AUTH0_SCOPE,
        auth0Config.AUTH0_AUDIENCE,
        auth0Config.AUTH0_ROLES,
      )
      const instance = await getMsalClientInstance(config)

      setLoadingConfig(false)
      setConfig(auth0Config)
      setMsalInstance(instance)

      const clientName: string = config?.CLIENT_NAME ?? ''
      const theme = getClientTheme(clientName)

      document.documentElement.setAttribute('client-theme', theme)
      updateBrowserTabInfo(clientName)
    }

    loadConfig()
  }, [])

  if (loadingConfig) return <LoadingScreen />
  if (!msalInstance) return getMainElement(<App />)

  return getMainElement(
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>,
  )
}

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container!)
root.render(<Main />)
