import { FC } from 'react'
import ConfirmModal from '../modals/ConfirmModal'
import { ChatTypeEnum } from '../../enums/ChatTypeEnum'
import { useLanguages } from '../../hooks/useLanguages'
import { ISharepointFileModel } from '../../models/INomicModel'
import Style from '../../styles/components/custom-app/ModalWarningCreateDraftCustomAppFromSharepoint.module.sass'
import { getTotalFileSizeInGB } from '../../helpers/fileUtils'

interface IModalWarningCreateDraftCustomAppFromSharepointProps {
  isOpen: boolean
  onClose: () => void
  onNext: () => void
  selectedSharepointFiles: ISharepointFileModel[]
}

const ModalWarningCreateDraftCustomAppFromSharepoint: FC<IModalWarningCreateDraftCustomAppFromSharepointProps> = (
  props,
) => {
  const { isOpen, onNext, onClose, selectedSharepointFiles } = props
  const { t } = useLanguages()

  const calculateCost = () => {
    const austDollar = 0.1
    const cost = (selectedSharepointFiles.length * austDollar).toFixed(2)
    return `$${cost}`
  }

  const modalContent = () => {
    return (
      <div className={Style.modalContent}>
        <p>{t('cra_cost_warning_popup_description1')}</p>

        <div>
          {t('cra_cost_warning_popup_description2')} <b>{selectedSharepointFiles.length}</b>
        </div>
        <div>
          {t('cra_cost_warning_popup_description3')} <b>{getTotalFileSizeInGB(selectedSharepointFiles)}</b>
        </div>
        <div>
          {t('cra_cost_warning_popup_description4')} <b>{calculateCost()}</b>
        </div>

        <p>{t('cra_cost_warning_popup_description5')}</p>
      </div>
    )
  }

  return (
    <ConfirmModal
      open={isOpen}
      title={t('Confirmation')}
      message={modalContent()}
      labelYes={t('confirm')}
      labelClose={t('cancel')}
      size='small'
      onClose={onClose}
      onSave={onNext}
      chatType={ChatTypeEnum.CUSTOM_RECALL_APP}
    />
  )
}

export default ModalWarningCreateDraftCustomAppFromSharepoint
