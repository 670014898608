import { FC, DragEvent, ClipboardEvent, useRef, useState, useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Icon, useToast } from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

import { ChatSession, ChatType, FullScreen, NomicSession, QuestionFile, ShowExtendedInput } from '../stores/AppStore'
import { ChatTypeConfig, ChatTypeInput, ChatTypeEnum } from '../enums/ChatTypeEnum'
import FileAttachment from './FileAttachment'
import FileAttachmentButton from './FileAttachmentButton'
import SubmitButton from './SubmitButton'
import FocusButton from './FocusButton'
import { fileExtension } from '../helpers/fileUtils'
import { MAX_NO_ATTACHMENTS } from '../config/config'
import { useLanguages } from '../hooks/useLanguages'
import { AppParams } from '../enums/AppConstants'
import { AppRoute } from '../enums/AppRouteConstants'

import Style from '../styles/QuestionBox.module.sass'
import { ICreateChatResponseModel } from '../models/api/ICreateChatModels'
import ExpandButton from './ExpandButton'

export interface IHandleFileChangeProps {
  chatType: number
  files: FileList
}

export interface IQuestionBoxProps {
  cssClassName?: string
  createNewChatCallback?: (chat: ICreateChatResponseModel) => void
  handleExpand?: (isExpanded: boolean) => void
  expand?: boolean
}

const QuestionBox: FC<IQuestionBoxProps> = (props) => {
  const { cssClassName, createNewChatCallback, handleExpand, expand } = props

  const [questionFile, setQuestionFile] = useRecoilState(QuestionFile)
  const chatSession = useRecoilValue(ChatSession)
  const setChatSession = useSetRecoilState(ChatSession)
  const [nomicSession, setNomicSession] = useRecoilState(NomicSession)

  const chatType = useRecoilValue(ChatType)
  const fullScreen = useRecoilValue(FullScreen)
  const showExtendedInput = useRecoilValue(ShowExtendedInput)
  const [dropArea, setDropArea] = useState(0)
  const fileUploadRef = useRef<HTMLInputElement>(null)
  const imageUploadRef = useRef<HTMLInputElement>(null)
  const questionBoxRef = useRef<HTMLInputElement>(null)
  const { t } = useLanguages()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const isNewSession = searchParams.get(AppParams.NEW_SESSION) === AppParams.NEW_SESSION_VALUE

  useEffect(() => {
    if (isNewSession) {
      setChatSession(null)
      setNomicSession(null)
    }
  }, [isNewSession, setChatSession, setNomicSession])

  const { addToast } = useToast()
  const loading = chatSession?.questions.some((q) => !!q.loading)

  const handleFileChange = (props: IHandleFileChangeProps) => {
    if (!props.files?.length || !props.chatType) return

    const currentFiles = questionFile || []
    if (currentFiles.length + props.files.length > 3) {
      addToast({
        type: 'warning',
        title: t('popup_toast3'),
        message: t('popup_mess10', { maxFiles: MAX_NO_ATTACHMENTS }),
        timeout: 5000,
        timeLabel: t('popup_toast_timelabel'),
      })
    }

    const { maxFiles, extensions } = ChatTypeConfig[props.chatType]

    const files = Array.from(props.files).filter((file) => {
      const fileExt = fileExtension(file.name)
      if (!extensions.some((ext) => ext === fileExt)) {
        addToast({
          type: 'error',
          title: t('popup_toast2'),
          message: t('popup_mess', { filename: file.name, file_types: extensions.join(',') }),
          timeout: 5000,
          timeLabel: t('popup_toast_timelabel'),
        })
        return false
      }

      return true
    })

    setQuestionFile([...currentFiles, ...files].splice(0, maxFiles))
  }

  const handleDropEnter = (e: DragEvent<HTMLDivElement>) => {
    if (!chatType || !ChatTypeInput[chatType].fileDrop || loading) return
    setDropArea((v) => v + 1)

    e.preventDefault()
    e.stopPropagation()
  }

  const handleDropLeave = (e: DragEvent<HTMLDivElement>) => {
    if (!chatType || !ChatTypeInput[chatType].fileDrop) return
    setDropArea((v) => v - 1)

    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    if (!chatType || !ChatTypeInput[chatType].fileDrop) return
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    if (loading) {
      setDropArea(0)
      return
    }

    if (!chatType || !ChatTypeInput[chatType].fileDrop) return
    setDropArea(0)

    handleFileChange({
      files: e.dataTransfer.files,
      chatType,
    })

    e.preventDefault()
    e.stopPropagation()
  }

  const handlePaste = (e: ClipboardEvent<HTMLDivElement>) => {
    if (!chatType || !ChatTypeInput[chatType].fileDrop || loading) return
    setDropArea(0)

    handleFileChange({
      files: e.clipboardData.files,
      chatType,
    })

    e.stopPropagation()
  }

  if (chatType === null) return null

  const wrapperClasses = classNames(
    {
      [Style.questionInputWrapper]: true,
      [Style.topOpen]: showExtendedInput,
      [Style.fullscreen]: fullScreen,
    },
    cssClassName,
  )

  const renderChatNotAvailableAlert = () => {
    if (chatType === ChatTypeEnum.CODE) {
      return (
        <div className={Style.questionInput}>
          <div className={Style.questionInputReplaceText}>
            Code Assistant is no longer available. Please use{' '}
            <a
              href={`#/${AppRoute.GPT_CHAT}?${AppParams.NEW_SESSION}=${AppParams.NEW_SESSION_VALUE}`}
              className={Style.link}
            >
              Secure ChatGPT
            </a>{' '}
            for your code questions.
          </div>
        </div>
      )
    } else if (chatType === ChatTypeEnum.CUSTOM_RECALL_APP && nomicSession?.deleted) {
      return (
        <div className={Style.questionInput}>
          <div className={Style.questionInputReplaceText}>
            This Knowledge Agent is no longer available. You can create your own{' '}
            <a href={`#/${AppRoute.CUSTOM_RECALL_APP}`} className={Style.link}>
              Knowledge Agents here
            </a>
          </div>
        </div>
      )
    }
    return null
  }

  const renderAddFileArea = () => {
    if (loading || !dropArea) return null

    return (
      <div className={Style.dropBox}>
        <div>
          <Icon type='attachment' />
          <br />
          Add file(s)
        </div>
      </div>
    )
  }

  return (
    <div
      ref={questionBoxRef}
      className={wrapperClasses}
      onDragEnter={handleDropEnter}
      onDragLeave={handleDropLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onPaste={handlePaste}
      role='none'
    >
      {renderAddFileArea()}
      {ChatTypeInput[chatType].file && <FileAttachment />}
      {renderChatNotAvailableAlert() || (
        <div className={Style.questionInput}>
          {ChatTypeInput[chatType].focus && <FocusButton />}
          {ChatTypeInput[chatType].expand && (
            <ExpandButton
              expand={expand}
              handleExpand={handleExpand}
              disabled={chatSession?.questions.some((q) => q.loading || q.submitting)}
            />
          )}
          {ChatTypeInput[chatType].file && (
            <FileAttachmentButton
              disabled={loading || false}
              fileUploadRef={fileUploadRef}
              handleFileChange={handleFileChange}
              chatType={chatType}
            />
          )}
          <SubmitButton
            chatType={chatType}
            fileUploadRef={fileUploadRef}
            imageUploadRef={imageUploadRef}
            createNewChatCallback={createNewChatCallback}
          />
        </div>
      )}
    </div>
  )
}

export default QuestionBox
