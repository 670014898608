import { FC, useEffect, useMemo, useState, useContext } from 'react'
import {
  Button,
  Dropdown,
  Loader,
  Pagination,
  Pill,
  ThemeContext,
  ThemeEnum,
} from '@aurecon-creative-technologies/styleguide'
import { useMediaQuery } from 'react-responsive'
import { appInsightsInstance } from '../api/AppInsights'
import Page from '../components/Page'
import { UI_WIDTH_COLLAPSE } from '../config/config'

import { getFeedback } from '../api/FeedbackService'
import { IQuestion } from '../models/IQuestionModels'
import SeeQuestionModal from '../components/modals/SeeQuestionModal'
import ChatFeedback from '../components/ChatFeedback'
import { RatingEnum } from '../enums/RatingEnum'
import { auth0Permissions } from '../config/permissions'
import { convertJSONtoBlob, downloadAction } from '../helpers/downloadFile'
import { FeedbackTypeEnum, FeedbackTypeToLabel } from '../enums/FeedbackTypeEnum'
import { convertToBinArray } from '../helpers/convertToBinArray'
import { feedbackTypes } from '../helpers/feedbackTypeToLabel'

import Style from '../styles/Dashboard.module.sass'
import { useHasPermissions } from '../hooks/useHasPermissions'

const PAGE_LENGTH = 20

const FILTER_TIME = [
  { id: '0', label: 'All time' },
  { id: '30', label: '1 month' },
  { id: '60', label: '2 months' },
  { id: '90', label: '3 months' },
  { id: '180', label: '6 months' },
]

const FILTER_TYPE = [
  { id: RatingEnum.NO_RATING.toString(), label: 'Any' },
  { id: RatingEnum.POSITIVE.toString(), label: 'Thumb Up' },
  { id: RatingEnum.NEGATIVE.toString(), label: 'Thumb Down' },
]

const FILTER_CAT = [
  { id: FeedbackTypeEnum.COHERENCE, label: FeedbackTypeToLabel[FeedbackTypeEnum.COHERENCE] },
  { id: FeedbackTypeEnum.ACCURACY, label: FeedbackTypeToLabel[FeedbackTypeEnum.ACCURACY] },
  { id: FeedbackTypeEnum.CONSISTENCY, label: FeedbackTypeToLabel[FeedbackTypeEnum.CONSISTENCY] },
  { id: FeedbackTypeEnum.REDUNDANCY, label: FeedbackTypeToLabel[FeedbackTypeEnum.REDUNDANCY] },
  { id: FeedbackTypeEnum.SENSITIVE, label: FeedbackTypeToLabel[FeedbackTypeEnum.SENSITIVE] },
]

const ALL_CATEGORIES = [
  FeedbackTypeEnum.COHERENCE,
  FeedbackTypeEnum.ACCURACY,
  FeedbackTypeEnum.CONSISTENCY,
  FeedbackTypeEnum.REDUNDANCY,
  FeedbackTypeEnum.SENSITIVE,
]

const Dashboard: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [questionsRaw, setQuestionsRaw] = useState<IQuestion[]>([])
  const [timeFilter, setTimeFilter] = useState(30)
  const [typeFilter, setTypeFilter] = useState(RatingEnum.NO_RATING.toString())
  const [catFilter, setCatFilter] = useState<(string | number)[]>(ALL_CATEGORIES)
  const { theme } = useContext(ThemeContext)
  const { hasPermissions } = useHasPermissions()

  const canAccessDashboard = hasPermissions(auth0Permissions.FEEDBACK_VIEW)
  const canExportFeedback = hasPermissions(auth0Permissions.FEEDBACK_EXPORT)
  const isDesktop = useMediaQuery({ minWidth: UI_WIDTH_COLLAPSE })

  const appInsights = appInsightsInstance()
  if (appInsights) appInsights.trackPageView({ name: 'Dashboard' })

  useEffect(() => {
    const asyncFunc = async () => {
      setLoading(true)

      const response = await getFeedback({ days: timeFilter, type: typeFilter })
      if (response?.data) {
        setQuestionsRaw(response.data)
      }

      setLoading(false)
    }

    asyncFunc()
  }, [timeFilter, typeFilter])

  const questions = useMemo(() => {
    if (catFilter.length === ALL_CATEGORIES.length) return questionsRaw

    return questionsRaw.filter((q) => {
      const cat = convertToBinArray(q.feedbackType || 0, ALL_CATEGORIES.length)
      return cat.some((c) => catFilter.includes(c))
    })
  }, [questionsRaw, catFilter])

  if (!canAccessDashboard) {
    location.hash = `#/`
    return null
  }

  const totalPages = Math.ceil(questions.length / PAGE_LENGTH)
  const start = (page - 1) * PAGE_LENGTH
  const pageQuestions = questions.slice(start, start + PAGE_LENGTH)

  const handlePagination = (p: number) => {
    if (!p) return

    setPage(p)
  }

  const handleSelectTimeFilter = async (selected: string | number) => {
    const time = Number(selected)
    setTimeFilter(time)
  }

  const handleSelectTypeFilter = async (selected: string | number) => {
    setTypeFilter(selected.toString())
  }

  const handleSelectCatFilter = (selected: (string | number)[]) => {
    setCatFilter(selected)
  }

  const handleDownload = () => {
    const exportData = questions.map((q) => {
      const { feedbackNumber } = feedbackTypes(q.feedbackType || 0)

      return {
        rowKey: q.rowKey,
        answer: q.answers[0] ? q.answers[0].answer : '',
        createdAt: q.createdAt,
        updatedAt: q.updatedAt,
        edited: q.edited,
        feedback: q.feedback,
        feedbackType: q.feedbackType,
        finishReason: q.finishReason,
        question: q.question,
        rating: q.rating,
        usedTokens: q.usedTokens,
        accuracy: feedbackNumber.includes(FeedbackTypeEnum.ACCURACY),
        coherence: feedbackNumber.includes(FeedbackTypeEnum.COHERENCE),
        consistency: feedbackNumber.includes(FeedbackTypeEnum.CONSISTENCY),
        redundancy: feedbackNumber.includes(FeedbackTypeEnum.REDUNDANCY),
        sensitive: feedbackNumber.includes(FeedbackTypeEnum.SENSITIVE),
      }
    })

    const data = convertJSONtoBlob(exportData)

    downloadAction(data, 'feedback.json')
  }

  const resetFilters = () => {
    setTimeFilter(30)
    setTypeFilter(RatingEnum.NO_RATING.toString())
    setCatFilter(ALL_CATEGORIES)
  }

  return (
    <Page menu contentWrapper>
      <h1>AI Powered Apps</h1>
      <div className={Style.searchFilter}>
        <div className={Style.item}>
          When{' '}
          <Dropdown
            items={FILTER_TIME}
            size='small'
            selectedItem={`${timeFilter}`}
            onSelectItem={handleSelectTimeFilter}
          />
        </div>
        <div className={Style.item}>
          Type{' '}
          <Dropdown
            items={FILTER_TYPE}
            size='small'
            selectedItem={`${typeFilter}`}
            onSelectItem={handleSelectTypeFilter}
          />
        </div>
        <div className={Style.item}>
          Filter{' '}
          <Dropdown
            items={FILTER_CAT}
            size='small'
            multiple
            toggleAllItem='Toggle all'
            selectedMultipleItems={catFilter}
            onSelectMultipleItems={handleSelectCatFilter}
          />
        </div>
        <div className={Style.item}>
          <Button type='text' label='Clear filter' onClick={resetFilters} cssClass={Style.clearFilterButton} />
        </div>
        <div className={Style.item}>
          Results: <Pill colour={theme === ThemeEnum.LIGHT ? 6 : 12}>{questions.length}</Pill>
        </div>
        <div className={Style.download}>
          <Button
            label='Download JSON'
            onClick={handleDownload}
            disabled={!canExportFeedback}
            cssClass={Style.downloadJsonButton}
          />
        </div>
      </div>

      {loading && <Loader label='Loading questions...' />}

      {!loading && (
        <>
          <p>
            Page {page} out of {totalPages}
          </p>

          {!pageQuestions.length && <p className={Style.messag}>No feedback found</p>}

          {pageQuestions.map((question) => {
            return <ChatFeedback key={question.rowKey} question={question} />
          })}

          <Pagination
            page={page}
            pageCount={totalPages}
            smallChevron
            onChange={handlePagination}
            cssClass={Style.pagination}
            minimal={!isDesktop}
          />
        </>
      )}
      <SeeQuestionModal />
    </Page>
  )
}

export default Dashboard
