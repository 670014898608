import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser'

import { IAppConfig } from '../api/AppService'

const getMsalClientInstance = async (config: IAppConfig | null): Promise<PublicClientApplication> => {
  const clientId: string = config?.MSAL_CLIENT_ID ?? ''
  const loginUrl: string = config?.MSAL_LOGIN_URL ?? ''

  const msalConfig: Configuration = {
    auth: {
      clientId: clientId, // This is the ONLY mandatory field that you need to supply.
      authority: loginUrl, // Replace the placeholder with your tenant subdomain
      navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  }
  const msalInstance = new PublicClientApplication(msalConfig)
  await msalInstance.initialize()

  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
  }

  // Listen for sign-in event and set active account
  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      msalInstance.setActiveAccount(account)
    }
  })

  return msalInstance
}

export { getMsalClientInstance }
