export enum actions {
  VIEW_DASHBOARD = 1,
  EDIT_HOMEPAGE = 2,
  EDIT_BANNER = 3,
  NOMIC_MAP_CREATOR = 4,
}

export enum Role {
  FeedbackViewer = '{appname}::{env}::feedbackViewer',
  BannerAdmin = '{appname}::{env}::bannerAdmin',
  HomeAdmin = '{appname}::{env}::homePublisher',
  NomicMapCreator = '{appname}::{env}::nomicMapCreator',
}

export const auth0Permissions = {
  BANNER_DISABLE: 'banner::disable',
  BANNER_EDIT: 'banner::edit',
  BANNER_ENABLE: 'banner::enable',
  FEEDBACK_EXPORT: 'feedback::export',
  FEEDBACK_VIEW: 'feedback::view',
  HOMEPAGE_ADD_ITEM: 'homePage::addItem',
  HOMEPAGE_DELETE_ITEM: 'homePage::deleteItem',
  HOMEPAGE_EDIT_ITEM: 'homePage::editItem',
}

export const permissions = {
  [actions.VIEW_DASHBOARD]: [Role.FeedbackViewer],
  [actions.EDIT_HOMEPAGE]: [Role.HomeAdmin],
  [actions.EDIT_BANNER]: [Role.BannerAdmin],
  [actions.NOMIC_MAP_CREATOR]: [Role.NomicMapCreator],
}

export const getPermissions = (appName: string, environment: string, action: number): string => {
  const permission = permissions[action] ?? ''
  return permission.toString().replace('{appname}', appName).replace('{env}', environment)
}
