import { deleteAsync, getAsync, postAsync, putAsync } from '../helpers/apiRequest'
import { IBodyRequestModel } from '../models/api/IBodyRequestModel'
import {
  ICustomAppResponseModel,
  IGetCustomAppNomicDatasetDetailsResponseModel,
  INomicCreateOrgResponseModel,
  INomicOrgResponseModel,
} from '../models/api/ICreateChatModels'
import {
  ICheckCraRequestModel,
  ICreateNomicOrgModel,
  IDeleteCraRequestModel,
  IGetCraRequestModel,
  IGetCustomAppNomicDatasetDetailsRequestModel,
  IGetNomicMapFieldsRequestModel,
  IUpdatedCraRequestModel,
} from '../models/api/ICreateCustomRecallModel'
import { IRemoveCraResponseModel } from '../models/api/ICreateCustomRecallShareModel'
import { IResponse } from '../models/api/IResponse'
import { INomicMapFieldsModel } from '../models/INomicModel'
import { TokenExpiryWrapper } from './TokenManager'

export const createCustomRecallApp = TokenExpiryWrapper(
  (request: ICheckCraRequestModel): Promise<IResponse<ICustomAppResponseModel>> => postAsync('/v1/cra', request),
  [],
  null,
)

export const getAllCustomApps = TokenExpiryWrapper(
  (request: IBodyRequestModel): Promise<IResponse<ICustomAppResponseModel[]>> => getAsync('/v1/cra', request),
  [],
  null,
)

export const getCustomApp = TokenExpiryWrapper(
  async (request: IGetCraRequestModel): Promise<IResponse<ICustomAppResponseModel>> =>
    getAsync(`/v1/cra/custom-app/${request.id}`, request),
  [],
  null,
)

export const getCustomAppNomicDatasetDetails = TokenExpiryWrapper(
  async (
    request: IGetCustomAppNomicDatasetDetailsRequestModel,
  ): Promise<IResponse<IGetCustomAppNomicDatasetDetailsResponseModel>> =>
    getAsync(`/v1/cra/custom-app-nomic-dataset/${request.id}`, request),
  [],
  null,
)

export const getNomicOrganisations = TokenExpiryWrapper(
  async (request: IBodyRequestModel): Promise<IResponse<INomicOrgResponseModel[]>> =>
    getAsync(`/v1/cra/my-nomic-orgs`, request),
  [],
  null,
)

export const createOrganisation = TokenExpiryWrapper(
  async (request: ICreateNomicOrgModel): Promise<IResponse<INomicCreateOrgResponseModel>> =>
    postAsync(`/v1/cra/new-nomic-org`, request),
  [],
  null,
)

export const publishCustomRecallApp = TokenExpiryWrapper(
  (request: IUpdatedCraRequestModel): Promise<IResponse<ICustomAppResponseModel>> => putAsync('/v1/cra', request),
  [],
  null,
)

export const deleteDraftCustomRecallApp = TokenExpiryWrapper(
  (request: IUpdatedCraRequestModel): Promise<IResponse<IRemoveCraResponseModel>> =>
    deleteAsync('/v1/cra/draft', request),
  [],
  null,
)

export const deletePublishedCustomRecallApp = TokenExpiryWrapper(
  (request: IDeleteCraRequestModel): Promise<IResponse<IRemoveCraResponseModel>> =>
    deleteAsync('/v1/cra/published', request),
  [],
  null,
)

export const getCRADefaultPrompt = TokenExpiryWrapper(
  async (request: IBodyRequestModel): Promise<IResponse<string>> => getAsync(`/v1/cra/default-prompt`, request),
  [],
  null,
)

export const getNomicMapFields = TokenExpiryWrapper(
  async (request: IGetNomicMapFieldsRequestModel): Promise<IResponse<INomicMapFieldsModel>> =>
    postAsync(`/v1/cra/fields`, request),
  [],
  null,
)
