export const ClientEnum = {
  AURECON: 'Aurecon',
  TF_NSW: 'TfNSW',
}

export const BrowserTabTitle = {
  [ClientEnum.AURECON]: 'Aurecon Recall',
  [ClientEnum.TF_NSW]: 'Transport for NSW',
}

export const BrowserTabIconPath = {
  [ClientEnum.AURECON]: '/recall_favicon_512.ico',
  [ClientEnum.TF_NSW]: '/nsw_favicon.ico',
}
